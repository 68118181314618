import React from 'react';
import map from '../../assets/201_map.png'
import {brands} from '../../store/brands'
import {carousels} from '../../store/carousels'
import Block from "../Block/Block";
import phone from '../../assets/101_phone_icon.png'
import email from '../../assets/Group 17.png'
import viber from '../../assets/101_viber_icon.png'

const Footer = ({isExtended, isMapVisible}) => {

    const getBrands = () => {
        return brands.map(brand => <div className='flex items-center justify-center m-2' key={brand.brand}>
            <img src={'./assets/brands/' + brand.image} alt={brand.brand + ' logó'}/>
        </div>)
    }

    const getCarouselItems = () => {
        return carousels.map((carouselItem, index) => <div
            className={`carousel-item relative ${index === 1 && 'active'} float-left w-full`}
            key={carouselItem.person}>
            <div className="md:w-2/3 mx-auto bg-white px-5 md:px-20 py-10 text-center shadow-lg my-5">
                <p className='md:text-[18px] mb-5 italic'>"{carouselItem.text}"</p>
                <p>({carouselItem.person})</p>
            </div>

        </div>)
    }
    return (<div>
        <div className={''}>
            {isMapVisible && <Block color={'bg-baseGrey'}>
                <h3 className='uppercase text-[40px] mb-5 uppercase text-center'>kiszállási területek</h3>
                <div className="flex flex-col md:grid md:grid-cols-3 md:gap-x-5 px-5">

                    <img src={map} alt="Kiszállási terültek"/>
                    <div
                        className="flex flex-col mb-5 border-b-2 md:border-r-2 md:border-b-0 border-black text-center pb-5">
                        <h5 className='font-bold mb-5'>Budapest</h5>
                        <div className="grid ">
                            <article>
                                <ul>
                                    <li>III. kerület</li>
                                    <li>IV. kerület</li>
                                    <li>VI. kerület</li>
                                    <li>VII. kerület</li>
                                    <li>VIII. kerület</li>
                                    <li>IX. kerület</li>
                                    <li>X. kerület</li>
                                    <li>XIII. kerület</li>
                                    <li>XIV. kerület</li>
                                    <li>XV. kerület</li>
                                    <li>XVI. kerület</li>
                                    <li>XVII. kerület</li>
                                </ul>
                            </article>
                        </div>
                    </div>
                    <div className="flex flex-col md:pl-10 text-center">
                        <h5 className='font-bold mb-5'>Vidék</h5>
                        <div className="grid ">
                            <article className={''}>
                                <ul>
                                    <li>Budakalász</li>
                                    <li>Pomáz</li>
                                    <li>Dunakeszi</li>
                                    <li>Fót</li>
                                    <li>Csömör</li>
                                    <li>Kistarcsa</li>
                                    <li>Nagytarcsa</li>
                                </ul>
                            </article>
                        </div>
                    </div>
                </div>
            </Block>}


        </div>
        {isExtended && <>
            <Block>
                <h3 className='uppercase text-[30px] my-5 uppercase text-center md:text-left'>Márkák melyeket
                    javítok</h3>
                <p className='py-5'>
                    AEG, ELECTROLUX, ZANUSSI, WHIRLPOOL, BAUKNECHT, IKEA, INDESIT,ARISTON, SIEMENS, BOSCH, LG,
                    SAMSUNG,
                    HAJDU, GORENJE, FAGOR, CANDY, BEKO
                </p>
                <div className=" grid grid-cols-3 gap-5 md:grid-cols-5">
                    {getBrands()}
                </div>
            </Block>
            <Block color={'bg-baseGrey'}>
                <div id="carouselExampleControls" className="carousel slide relative" data-bs-ride="carousel">
                    <div className="carousel-inner relative w-full overflow-hidden">
                        {getCarouselItems()}
                    </div>
                    <button
                        className="carousel-control-prev bg-brandPrimary w-10 h-10 -translate-x-5 md:translate-x-0 absolute top-1/2 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
                        type="button"
                        data-bs-target="#carouselExampleControls"
                        data-bs-slide="prev"
                    >
                            <span className="carousel-control-prev-icon inline-block bg-no-repeat"
                                  aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                        className="carousel-control-next bg-brandPrimary w-10 h-10 translate-x-5 md:translate-x-0 absolute top-1/2 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
                        type="button"
                        data-bs-target="#carouselExampleControls"
                        data-bs-slide="next"
                    >
                            <span className="carousel-control-next-icon inline-block bg-no-repeat"
                                  aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </Block>
        </>}
        <Block color={'bg-brandDark'}>
            <h3 className={'text-white text-[40px] my-5 uppercase text-left md:text-center lg:text-left'}>kapcsolat</h3>
            <div className="flex flex-col md:items-center lg:grid grid-cols-3 ">
                <div className="flex flex-col gap-5 lg:order-1">
                    <div className="flex gap-x-5">
                        <img src={phone} alt="telefonszám"/>
                        <a href='tel:+36708010502' className='text-white underline hover:text-brandPrimary'>+36 70
                            801 0502</a>
                    </div>
                    <div className="flex gap-x-5">
                        <img src={email} alt="email cím"/>
                        <a href='mailto:neptunusz.szerviz@gmail.com'
                           className='text-white underline hover:text-brandPrimary'>neptunusz.szerviz@gmail.com</a>
                    </div>
                    <div className="flex gap-x-5">
                        <img src={viber} alt="viber"/>
                        <a href="viber://chat/?number=%2B36708010502"
                           className='text-white underline hover:text-brandPrimary'>viber/+36 70
                            801 0502</a>
                    </div>
                    <div className="lg:flex gap-x-5 hidden">
                        <p className={"text-white"}>Nyilvántartási szám 56985357</p>
                    </div>
                    <div className="lg:flex gap-x-5 hidden  ">
                        <p className={"text-white"}>Adószám: 58487563-2-42</p>
                    </div>
                </div>

                <div className="flex flex-col text-white uppercase underline gap-y-4 my-5 lg:my-0 lg:order-2">
                    <a href="/szolgaltatasaim" className={'hover:text-brandPrimary'}>szolgáltatásaim</a>
                    <a href="/arak" className={'hover:text-brandPrimary'}>árak</a>
                    <a href="/munkaim" className={'hover:text-brandPrimary'}>munkáim</a>
                    <a href="/hasznos-tanacsok" className={'hover:text-brandPrimary'}>hasznos tanácsok</a>
                    <a href="/kapcsolat" className={'hover:text-brandPrimary'}>kapcsolat</a>
                </div>
            </div>

        </Block>

    </div>);
};

export default Footer;
