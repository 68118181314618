import React, {useEffect, useState} from 'react';
import logo from '../../assets/201_logo.png'
import desktopLogo from '../../assets/202_logo.png'
import phone from '../../assets/101_phone_icon.png'
import viber from '../../assets/101_viber_icon.png'
import clock from '../../assets/101_clock_icon.png'
import {NavLink} from "react-router-dom";

const Navigation = () => {
    const [small, setSmall] = useState(false);
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [isHovering, setIsHovering] = useState(false);

    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener("scroll", () => setSmall(window.pageYOffset > 10));
        }
    }, []);


    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };

    return (<div className='w-full flex flex-col bg-brandDark lg:bg-white fixed top-0 z-20 px-10'>
        <div
            className="hidden lg:flex gap-x-12 pl-32 items-center justify-end container pt-5 w-full bg-white relative text-baseText mx-auto px-4">
            <a href="tel:+36708010502" className="flex items-center gap-x-2">
                <img src={phone} alt=""/>+36 70 801 0502
            </a>
            <a href="viber://chat/?number=%2B36708010502" className="flex items-center gap-x-2">
                <img src={viber} alt=""/>viber/+36 70 801 0502
            </a>
            <div className="flex items-center gap-x-2">
                <img src={clock} alt=""/>
                <div className="">
                    <div className="">Hétfő-Péntek</div>
                    <div className="">8:00-17:00</div>
                </div>
            </div>

        </div>
        <div className="container flex justify-end mx-5 md:mx-auto ">

            <div
                className="hidden lg:flex items-center justify-evenly  py-5 w-full bg-brandDark relative text-white pl-32 uppercase translate-y-1/2">
                {!small ? <NavLink to='/'><img src={logo} alt="Neptunus logo"
                                               className='max-w-[15%] xl:max-w-[200px] absolute -left-6 -top-12 xl:-top-24 '/></NavLink> :
                    <NavLink to='/'><img src={desktopLogo} alt="Neptunus logo"
                                         className='max-w-[15%] xl:max-w-[250px] absolute left-0 -top-[104px] z-30 '/></NavLink>}
                <div onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} className={'relative'}>
                    <NavLink to='/szolgaltatasaim'
                             className='navigation-link'>Szolgáltatásaim</NavLink>
                    {isHovering && (<div
                        className='hidden md:flex items-center absolute py-5 px-10 bg-brandPrimary gap-y-5 flex flex-col'>
                        <NavLink className='navigation-link-alt hover:text-brandDark w-full'
                                 to={'/szolgaltatasaim/mosogep'}>
                            Mosógép
                        </NavLink>
                        <NavLink className='navigation-link-alt hover:text-brandDark w-full'
                                 to={'/szolgaltatasaim/mosogatogep'}>
                            Mosogatógép
                        </NavLink>
                        <NavLink className='navigation-link-alt hover:text-brandDark w-full'
                                 to={'/szolgaltatasaim/szaritogep'}>
                            Szárítógép
                        </NavLink>
                        <NavLink className='navigation-link-alt hover:text-brandDark w-full'
                                 to={'/szolgaltatasaim/klima'}>
                            Klíma
                        </NavLink>
                    </div>)}
                </div>

                <NavLink to='/arak' className='navigation-link'>Árak</NavLink>
                <NavLink to="/munkaim" className='navigation-link'>Munkáim</NavLink>
                <NavLink to="/hasznos-tanacsok" className='navigation-link'>Hasznos tanácsok</NavLink>
                <NavLink to="/kapcsolat" className='navigation-link'>Kapcsolat</NavLink>
            </div>
            {!small ? <NavLink to='/'><img src={logo} alt="Neptunus logo"
                                           className='drop-shadow-xl max-w-[100px] absolute left-5 top-0 lg:hidden'/></NavLink> :
                <NavLink to='/'><img src={desktopLogo} alt="Neptunus logo"
                                     className='max-w-[50px] absolute left-5  lg:hidden z-30 bg-white'/></NavLink>}


            <section className="flex lg:hidden">
                <div
                    className="space-y-2 py-3"
                    onClick={() => setIsNavOpen((prev) => !prev)}>
                    <span className="block h-0.5 w-8 bg-white"></span>
                    <span className="block h-0.5 w-8 bg-white"></span>
                    <span className="block h-0.5 w-8 bg-white"></span>

                </div>

                <div className={isNavOpen ? "showMenuNav" : "hideMenuNav"}>
                    <div
                        className=" absolute top-0 right-0 px-5 py-2"
                        onClick={() => setIsNavOpen(false)}>
                        <svg
                            className="h-8 w-8 text-gray-600"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#ffffff"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <line x1="18" y1="6" x2="6" y2="18"/>
                            <line x1="6" y1="6" x2="18" y2="18"/>
                        </svg>
                    </div>
                    <ul className="flex flex-col items-center justify-between min-h-[250px]">
                        <li className="my-8 uppercase">
                            <NavLink to='/szolgaltatasaim' className='navigation-link'>Szolgáltatásaim</NavLink>
                        </li>

                        <li className="my-8 uppercase">
                            <NavLink to='/arak' className='navigation-link'>Árak</NavLink>
                        </li>
                        <li className="my-8 uppercase">
                            <NavLink to="/munkaim" className='navigation-link'>Munkáim</NavLink>
                        </li>
                        <li className="my-8 uppercase">
                            <NavLink to="/hasznos-tanacsok" className='navigation-link'>Hasznos tanácsok</NavLink>
                        </li>
                        <li className="my-8 uppercase">
                            <NavLink to="/kapcsolat" className='navigation-link'>Kapcsolat</NavLink>
                        </li>
                    </ul>
                </div>
            </section>

        </div>
    </div>);
};

export default Navigation;
