import React, {useEffect} from 'react';
import Layout from "../../Components/Layout/Layout";
import Footer from "../../Components/Footer/Footer";
import Block from "../../Components/Block/Block";
import clock from '../../assets/101_clock_icon.png';
import w1 from '../../assets/101_icon1.png';
import w2 from '../../assets/101_icon2.png';
import w3 from '../../assets/101_icon3.png';
import w4 from '../../assets/101_icon4.png';
import {Helmet} from "react-helmet";
import Card from "../../Components/Card/Card";


const Services = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (<Layout>
        <Helmet>
            <title>Neptunusz szerviz - Szolgáltatásaim</title>
            <meta name="description" content='Mosógépek, mosogatógép, szárítógépek, klímák nélkül ma már el sem tudjuk képzelni az életünket. Mint minden dolog, ami el tud romlani, egyszer sajnos ez is elromlik és ekkor
                                    szüksége
                                    lehet egy profi szerelőre.'/>
            <meta name='keywords'
                  content='mosogep, mosogatogep, klima, szarito, javitas, muszeres meres, szereles, elektronikus szamla, keszpenz, bankkartya'/>
            <meta name='robots' content='follow,index'/>
        </Helmet>
        <div
            className={' w-full  mt-10 lg:mt-32 flex-col gap-4 md:gap-8 z-10'}>
            <div className=" pt-20 md:pt-28 container mx-auto px-5"><a href="/"
                                                                       className='link-primary text-black'>Kezdőlap</a> > <a
                href="/szolgaltatasaim" className='link-primary'>Szolgáltatásaim</a></div>
        </div>

        <Block>
            <h3 className='text-3xl uppercase mt-32 mb-10 lg:my-10 text-center md:text-left'>Szolgáltatások</h3>
            <div className="grid md:grid-cols-2 xl:grid-cols-4 gap-y-5 md:gap-10 w-full justify-center mb-10">
                <Card>
                    <div className="mx-auto flex items-center justify-center flex-col gap-y-5">
                        <img src={w1}
                             alt="mosógép javítás
                        , karbantartás üzembe helyezés" className='px-5'/>
                        <div className="text-center">
                            mosógép javítás, karbantartás üzembe helyezés
                        </div>
                        <a href="/szolgaltatasaim/mosogep" className="link-primary">bővebb infó</a>
                    </div>

                </Card><Card>
                <div className="mx-auto flex items-center justify-center flex-col gap-y-5">
                    <img src={w2}
                         alt="mosogató javítás
                        , karbantartás üzembe helyezés" className='px-5'/>
                    <div className="text-center">
                        mosogatógép
                        javítás,
                        karbantartás,
                        üzembe helyezés

                    </div>
                    <a href="/szolgaltatasaim/mosogatogep" className="link-primary">bővebb infó</a>
                </div>
            </Card><Card>
                <div className="mx-auto flex items-center justify-center flex-col gap-y-5">
                    <img src={w3}
                         alt="szárítógép javítás,
karbantartás,
üzembe helyezés
" className='px-5'/>
                    <div className="text-center">
                        szárítógép javítás,
                        karbantartás,
                        üzembe helyezés

                    </div>
                    <a href="/szolgaltatasaim/szaritogep" className="link-primary">bővebb infó</a>
                </div>
            </Card><Card>
                <div className="mx-auto flex h-full items-center justify-between flex-col gap-y-5">
                    <img src={w4}
                         alt="klíma tisztítás
" className='px-5 my-auto'/>
                    <div className="text-center">
                        klíma tisztítás

                    </div>
                    <a href="/szolgaltatasaim/klima" className="link-primary">bővebb infó</a>
                </div>
            </Card>
            </div>

        </Block>
        <Block>
            <div className="flex flex-col gap-y-5 lg:mx-auto lg:w-fit mb-24">
                <div className="flex gap-x-5 items-start"><img src={clock} alt=""/>
                    <div className="flex flex-col">
                        <div className="flex flex-col md:flex-row md:gap-x-3 md:items-center text-[16px]"><p>
                            <strong>Munkaidő:</strong>
                        </p>
                            <p className=''>Hétfő-Péntek</p>
                            <p className='text-[20px]'>8:00-17:00</p></div>
                        <div className="flex flex-col md:flex-row md:gap-x-3 md:items-center text-[16px]"><p>
                            <strong>Telefonos ügyelet:
                            </strong>
                        </p>
                            <p className=''>
                                Hétfő-Szombat
                            </p>
                            <p className='text-[20px]'>
                                8:00-19:00
                            </p>
                        </div>
                    </div>

                </div>

                <div className=" flex flex-col w-full lg:grid lg:grid-cols-2 gap-5">
                    <a href="mailto:neptunusz.szerviz@gmail.com"
                       className="btn-primary w-full text-center ">Küldjön&nbsp;üzenetet</a> <a
                    href="tel:+36708010502"
                    className="btn-primary w-full text-center ">hívjon&nbsp;most</a>
                </div>
            </div>
        </Block>
        <Footer isMapVisible={true}/>
    </Layout>);
};

export default Services;
