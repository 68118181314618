export const advices = [
    {
        id: 1,
        title: 'Mosógép vízkőtelenítés',
        description: <div className={'px-5'}>
            <p className='mb-5'>
                Sokan kérdezik, hogy milyen szert használjanak mosógépük védelmében, illetve ha már elszennyeződött
                mivel lehet azt eltávolítani. Első és legfontosabb dolog a megelőzés. A legtöbb lerakódás a nem
                megfelelő mennyiségű szerhasználat következtében történik, a mosószer túladagolás lerakódást okoz a gép
                belsejében, amely csökkenti a mosási hatékonyságot, a vízkővel együtt lerakódik a fűtőszálra csökkentve
                annak teljesítményét, növelve az ön energia költségét.
            </p>

            <p><strong>
                Megoldás:
            </strong></p>
            <ul className='list-disc list-inside'>
                <li>előírt mennyiségű szerhasználat</li>
                <li>vízlágyító használata (olcsó)</li>
                <li>ecetes átmosás ¼ évente</li>
            </ul>
        </div>
    },
    {
        id: 2,
        title: 'Ecetes átmosás',
        description:
            <div className={'px-5'}>
                <p className='mb-5'>
                    Valamennyi mosógép a mosóprogram elindítása után ürítéssel kezdi a programot, ezért NE ÖNTSÜK ELŐRE
                    A GÉPBE AZ ECETET, mert azt rögtön kiüríti a gép.
                </p>
                <p className='mb-5'>
                    <strong>Felültöltős gépnél</strong> Az átmosásnál 60-90C-os hosszú programot használjunk, és ne
                    legye benne ruha.
                    Nyomjuk meg a <strong className={'text-brandPrimary'}>start/pause</strong> gombot a víz felvétele
                    után, majd várjunk míg kinyílik a
                    tető. Öntsünk
                    bele 1liter 20%-os ecetet, csukjuk le a készülék tetőt, végül nyomjuk meg ismét a <strong
                    className={'text-brandPrimary'}>start/pause</strong>
                    gombot. Innen nincs más dolgunk, mint végig várnunk a mosást. !!Figyelem!!, mosás közben erős
                    ecetszag érezhető, fontos a megfelelő szellőztetés.
                </p>
                <p className='mb-5'>
                    <strong>Elöltöltős gépeknél</strong> Az átmosásnál 60-90C-os hosszú programot használjunk, és ne
                    legye benne ruha. A
                    víz felvétele közben vagy utána húzzuk ki résnyire a mosószertartó fiókót, majd öntsük bele 1liter
                    20%-os ecetet. Innen nincs más dolgunk, mint végig várnunk a mosást. !!Figyelem!!, mosás közben erős
                    ecetszag érezhető, fontos a megfelelő szellőztetés.
                </p>
                <p className=''>
                    <strong>Figyelem!</strong>
                </p>
                <p className='mb-5'>
                    Ezek háztartási, illetve általam lepróbált praktikák. Fontos! Mindig tartsa be a használati
                    útmutatásban leírtakat, ne térjen el annak tartalmától, ne keverjen össze különböző szereket, mert
                    az egészségkárosodást, és a gép károsodását okozhatja. Sajnos ezek a praktikák nagyobb
                    elszennyeződés következtében nem vezetnek eredményre, üzletekben kapható szerekre is vonatkozik, nem
                    mindig jelentenek megoldást a számunkra, ekkor nem marad más, mint a készülék megbontása, szerelő
                    által történő kipucolás.
                </p>
            </div>
    },
    {
        id: 3,
        title: 'Mosógép szagtalanítás',
        description: <div className={'px-5'}>
            <p className='mb-5'>
                Sokan panaszkodnak, hogy kellemetlen szagú a mosógépük, amit leggyakrabban a lerakódott öblítőszer
                penészesedése okoz.
            </p><p className='mb-5'>
            Megelőzés: az öblítőszerek általában koncentrátum formájában vannak jelen a piacon, ezt érdemes hígítani,
            ezzel megakadályozzuk a gép részeire való odatapadást, ami későbbi penészesedéshez vezet.
        </p>

            <p><strong>
                Megoldás:
            </strong></p>
            <ul className='list-disc list-inside'>
                <li>szellőztetés (nyitott készülék ajtó/tető)
                </li>
                <li>hypos átmosás</li>
            </ul>
        </div>
    },
    {
        id: 4,
        title: 'Hypos átmosás',
        description: <div className={'px-5'}>
            <p className='mb-5'>
                Valamennyi mosógép a mosóprogram elindítása után ürítéssel kezdi a programot, ezért NE ÖNTSÜK ELŐRE A
                GÉPBE A HYPOT, mert azt rögtön kiüríti a gép.
            </p>
            <p className='mb-5'>
                <strong>Felül töltős gépnél</strong> Az átmosásnál 60-90C-os hosszú programot használjunk, és ne legye
                benne ruha.
                Nyomjuk meg a start/pause gombot a víz felvétele után, majd várjunk míg kinyílik a tető. Öntsünk bele
                3dl háztartási hypot, csukjuk le a készülék tetőt, végül nyomjuk meg ismét a start/pause gombot. Innen
                nincs más dolgunk, mint végig várnunk a mosást. !!Figyelem!!, mosás közben erős hypo szag érezhető,
                fontos a megfelelő szellőztetés.
            </p>
            <p className='mb-5'>
                Az átmosás után indítsunk el egy üres, öblítő programot, ezzel kiöblítve a mosógépünket, hogy a
                következő mosásnál a ruhák ne sérüljenek
            </p>
            <p className='mb-5'>
                <strong>Elöl töltős gépeknél</strong> a víz felvétele közben vagy utána húzzuk ki résnyire a
                mosószertartó fiókót, majd öntsük bele 3dl háztartási hypot. Innen nincs más dolgunk, mint végig várnunk
                a mosást. !!Figyelem!!, mosás közben erős hypo szag érezhető, fontos a megfelelő szellőztetés.
            </p>
            <p className=''>
                <strong>Figyelem!
                </strong>
            </p>
            <p className='mb-5'>
                Ezek háztartási, illetve általam lepróbált praktikák. Fontos! Mindig tartsa be a használati útmutatásban
                leírtakat, ne térjen el annak tartalmától, ne keverjen össze különböző szereket, mert az
                egészségkárosodást, és a gép károsodását okozhatja. Sajnos ezek a praktikák nagyobb elszennyeződés
                következtében nem vezetnek eredményre, üzletekben kapható szerekre is vonatkozik, nem mindig jelentenek
                megoldást a számunkra, ekkor nem marad más, mint a készülék megbontása, szerelő által történő kipucolás.
            </p>
        </div>
    },
    {
        id: 5,
        title: 'Mosogatógépek',
        description: <div className={'px-5'}>
            <p className='mb-5'>
                A mosogatógépeknél is nagy hangsúlyt kell fektetni a megelőzésre. Szokásom mondani, hogy „a
                mosogatógép nem konyhamalac”. Étkezés után az éltemaradékot érdemes a kukába belesöpörni, hogy azok ne
                szennyezzék el a készüléket.
            </p>
            <ul className='list-disc list-inside'>
                <li>A szűrőt időközönként pucolni szükséges.
                </li>
                <li>Csak boltban kapható tisztítót használjon a gép átmosására. Ne használjon ecetet, illetve más nem
                    erre a célra gyártott szereket, mert a gép károsodását okozhatja.
                </li>
                <li> Ha olyan kapszulát/tablettát használ ami tartalmaz mosogatószert, öblítőt, illetve vízlágyítót (3in
                    1, All in) ebben az esetben nem szükséges sót és öblítőszert adagolnia külön a készülékbe, mert
                    ezeket a funkciókat a tabletta teljes körűen ellátja. Valamennyi mosogatógép kijelzőjén szerepel a
                    só és öblítőszer jelzés , amely világításakor jelzi az adott szer hiányát. Ezek NEM hibára utalnak,
                    csak a tényt jelzik, hogy hiány van belőlük. Ha 3 az 1 ben tablettát használ ekkor nem szükséges
                    pótolni őket.
                </li>
                <li>Egyes esetekben, ha cseppfoltosan szárad a pohár szükség lehet mégis plusz öblítőszer hozzáadására a
                    3 in 1 tablettán kívül, illetve ha nagyon kemény a víz, só adagolása is szükségszerű lehet.
                </li>
            </ul>
        </div>
    },
    {
        id: 6,
        title: 'Szárítógép',
        description: <div className={'px-5'}>
            <p className=''>
                <strong>Figyelem!</strong>
            </p><p className='mb-5'>
            Kézzel kicsavart, vizes ruhák nem kerülhetnek be a szárítógépbe, csak centrifugálás után szabad behelyezni
            őket. Minden szárítás után szükséges a szűrő(k) kipucolása, illetve a víztartály kiürítése.</p>
        </div>
    },
]
