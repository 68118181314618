import React from 'react';
import Navigation from "../Navigation/Navigation";

const Layout = ({children}) => {
    return (
        <div className='flex flex-col w-full  min-h-screen'>
            <Navigation/>
            {children}
        </div>
    );
};

export default Layout;
