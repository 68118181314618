import React, {useEffect} from 'react';
import Layout from "../../Components/Layout/Layout";
import Footer from "../../Components/Footer/Footer";
import Block from "../../Components/Block/Block";
import error from '../../assets/404.jpg';

const NotFound = () => {

        useEffect(() => {
            window.scrollTo(0, 0)
        }, [])

        return (<Layout>
            <div
                className={' w-full  mt-10 lg:mt-32 flex-col gap-4 md:gap-8 z-10'}>
                <div className=" pt-20 md:pt-28 container mx-auto px-5">
                </div>
            </div>
            <Block>
                <h1 className='text-center font-bold text-2xl md:text-[40px]'>A keresett oldal nem található!</h1>
                <img src={error} alt="" className='rounded-full  lg:w-1/2 mx-auto my-10'/>
                <p className='text-center my-5'>
                    <a href="/" className="btn-primary">Vissza a főoldalra</a>
                </p>

            </Block>
            <Footer isMapVisible={true}/>
        </Layout>);
    }
;

export default NotFound;
