import React from 'react';

const Card = ({children}) => {
    return (
        <div
            className='bg-white py-5 px-2 md:px-12 shadow-lg mx-5 hover:shadow-2xl hover:bg-gray-50 transition-all'>
            {children}
        </div>);
};

export default Card;
