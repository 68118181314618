import React, {useEffect} from 'react';
import Layout from "../../Components/Layout/Layout";
import Footer from "../../Components/Footer/Footer";
import Block from "../../Components/Block/Block";
import phone from '../../assets/101_phone_icon.png'
import viber from '../../assets/101_viber_icon.png'
import clock from '../../assets/101_clock_icon.png'
import email from '../../assets/Group 17.png'
import {Helmet} from "react-helmet";

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (<Layout>
        <Helmet>
            <title>Neptunusz szerviz - Kapcsolat</title>
            <meta name="description" content='Hétfő-Szombat: 8:00-19:00'/>
            <meta name='keywords'
                  content='email, telefon, viber, hetfo, szombat, telefonos ugyelet, uzenet'/>
            <meta name='robots' content='follow,index'/>
        </Helmet>
        <div
            className={' w-full  mt-10 lg:mt-32 flex-col gap-4 md:gap-8  z-10'}>
            <div className=" pt-20 md:pt-28 container mx-auto px-5"><a href="/"
                                                                       className='link-primary text-black'>Kezdőlap</a> > <a
                href="/kapcsolat" className='link-primary'>Kapcsolat</a></div>
        </div>
        <Block>
            <h1 className={' text-[40px] my-5 uppercase text-left'}> Kapcsolat </h1>
            <div className="flex flex-col gap-y-5 lg:w-fit lg:mx-auto">
                <div className=" flex gap-x-5 items-start"><img src={clock} alt=""/>
                    <div className=" flex flex-col">
                        <div className=" flex flex-col md:flex-row md:gap-x-3 md:items-center text-
            [16px]"><p>
                            <strong>Munkaidő:</strong>
                        </p>
                            <p className=''>Hétfő-Péntek</p>
                            <p className='text-[20px]'>8:00-17:00</p></div>
                        <div className="flex flex-col md:flex-row md:gap-x-3 md:items-center text-[16px]"><p>
                            <strong>Telefonos ügyelet:
                            </strong>
                        </p>
                            <p className=''>
                                Hétfő-Szombat
                            </p>
                            <p className='text-[20px]'>
                                8:00-19:00
                            </p>
                        </div>
                    </div>

                </div>
                <div className="flex gap-x-5 items-start"><img src={phone} alt=""/>
                    <div className="flex"><a href="tel:+36708010502" className='text-[16px] underline'>+36 70 801
                        0502</a></div>
                </div>
                <div className="flex gap-x-5 items-start"><img src={viber} alt=""/>
                    <div className="flex"><a href="viber://chat/?number=%2B36708010502"
                                             className='text-[16px] underline'>viber/ +36 70 801
                        0502</a></div>
                </div>
                <div className="flex gap-x-5 items-start"><img src={email} alt=""/>
                    <div className="flex"><a href="mailto:neptunusz.szerviz@gmail.com"
                                             className='text-[16px] underline'>neptunusz.szerviz@gmail.com</a></div>
                </div>

                <div className=" flex flex-col w-full md:grid md:grid-cols-2 gap-5">
                    <a href="mailto:neptunusz.szerviz@gmail.com"
                       className="btn-primary w-full text-center ">Küldjön&nbsp;üzenetet</a> <a
                    href="tel:+36708010502"
                    className="btn-primary w-full text-center ">hívjon&nbsp;most</a>
                </div>
            </div>
        </Block>
        <Footer isMapVisible={true}/>
    </Layout>)
        ;
};

export default Contact;
