import React, {useEffect} from 'react';
import Layout from "../../Components/Layout/Layout";
import Footer from "../../Components/Footer/Footer";
import Block from "../../Components/Block/Block";
import {advices} from '../../store/advices'
import {Helmet} from "react-helmet";

const Advices = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const getAdvices = () => {
        return advices.map(advice =>
            <div className="accordion-item bg-white mb-5" key={'accordion' + advice.id}>
                <h2 className="accordion-header mb-0" id={"heading" + advice.id}>
                    <button
                        className="accordion-button collapsed relative font-bold text-[22px] flex items-center w-full py-4 px-5 text-base text-black text-left bg-baseGrey border-0 rounded-none transition focus:outline-none"
                        type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + advice.id}
                        aria-expanded="false"
                        aria-controls={"collapse" + advice.id}>
                        {advice.title}
                    </button>
                </h2>
                <div id={"collapse" + advice.id} className="accordion-collapse collapse"
                     aria-labelledby={"heading" + advice.id}>
                    <div className="accordion-body py-4 px-5">
                        {advice.description}</div>
                </div>
            </div>)
    }

    return (<Layout>
        <Helmet>
            <title>Neptunusz szerviz - Hasznos tanácsok</title>
            <meta name="description"
                  content='Ezek háztartási, illetve általam lepróbált praktikák. Fontos! Mindig tartsa be a használati útmutatásban leírtakat, ne térjen el annak tartalmától, ne keverjen össze különböző szereket, mert az egészségkárosodást, és a gép károsodását okozhatja. Sajnos ezek a praktikák nagyobb elszennyeződés következtében nem vezetnek eredményre, üzletekben kapható szerekre is vonatkozik, nem mindig jelentenek megoldást a számunkra, ekkor nem marad más, mint a készülék megbontása, szerelő által történő kipucolás.'/>
            <meta name='keywords'
                  content='tanacsok, tippek, mosogep, mosogatogep, javítas, praktikak, haztartasi utmutato'/>
            <meta name='robots' content='follow,index'/>
        </Helmet>
        <div
            className={' w-full  mt-10 lg:mt-32 flex-col gap-4 md:gap-8  z-10'}>
            <div className=" pt-20 md:pt-28 container mx-auto px-5"><a href="/"
                                                                       className='link-primary text-black'>Kezdőlap</a> &gt; <a
                href="/hasznos-tanacsok" className='link-primary'>Hasznos tanácsok</a></div>
        </div>
        <Block>
            <h1 className={' text-[40px] my-5 uppercase text-left'}> Hasznos tanácsok </h1>
            <div className="accordion" id="accordion">
                {getAdvices()}
            </div>
            <a href="/" className="btn-primary">Vissza&nbsp;a&nbsp;kezdőoldalra</a>
        </Block>S
        <Footer/>
    </Layout>);
};

export default Advices;
