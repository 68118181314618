export const carousels = [{
    text: 'Szeretném ajánlani mindenkinek kedvenc szerelőmet Balázs Mándoki-t, a Neptunusz Szervizből. Kedves, pontos, becsületes és nem utolsó sorban minden, de tényleg MINDEN háztartási gépet meg tud szerelni.Én nagyon szeretem!!',
    person: 'Andi, XIV. kerület'
}, {
    text: 'Kedves, megbízható, precíz szakember. Mindenkinek jó szívvel ajánlom!',
    person: 'Judit'
}, {
    text: 'Balázs szakértelme már többször is kihúzott minket a csávából. Mindenkinek csak ajánlani tudom',
    person: 'Gábor'
}, {
    text: 'Megbízható, korrekt, alapos, kedves fiatalember, kiváló szerelő! Évek óta csak Őt keresem, ha valami probléma \n' +
        'van akár mosógéppel, akár a mosogatógéppel. Csak Őt ajánlom!',
    person: 'Éva'
}, {
    text: 'Pontos kiszállás, szakszerű szervìz, megbìzhatòság. Mindenkinek ajánlom.',
    person: 'András'
}, {
    text: 'Balàzs megbízható és felelősségteljes szakember, aki alapos munkát végez, korrekt àron. Évek òta őt hìvom, ha elromlik itthon valami.',
    person: 'Adrián'
}, {
    text: 'Többször fordultunk már Balázshoz különböző problémákkal mosógép és mosogatógép ügyben. Mindannyiszor meg is oldotta azokat. Mindent megtesz azért, hogy ne kelljen gépet cserélni. Az elmúlt 10 évben nálunk nem is volt erre szükség, hála Balázs szakszerű és alapos munkájának. A cserélendő alkatrészt is mindig hamar beszerezte. Végezetül észrevételeit érthetően elmagyarázza és további jó tanácsokkal lát el.',
    person: 'Orsi'
}, {
    text: 'Megbízható és lelkiismeretes szakember. A megbeszélt időpontokat betartja, munkáját pedig precízen végzi, és közben a lakás tisztaságára is vigyáz. Jó szívvel ajánlom mindenkinek.',
    person: 'Szilvia'
}]
