export const works = [{
    id: 1, image: './assets/104_image.png', description: <article>
        <p className="mb-5">Nadrágzsebben maradt pénzérme, centrifugánál lyukat ütött a mosógép üstfalán és ömlött a
            víz a mosógépből.</p>
        <p className="mb-5">A feladat nem okozott problémát, a gép szétszedése után műanyag hegesztéssel
            megjavítottam az üstöt, az ügyfél
            nagyon boldog volt, így nem kellett új gépet vásárolnia.</p>
    </article>
}, {
    id: 2, image: './assets/work2.jpg', description: <article>
        <p className="mb-5">AEG mosógéphez riasztottak hogy leveri a biztosítékot, segítsek rajtuk mert utaznának el
            és nincs tiszta ruhájuk.</p>
        <p className="mb-5">Sikerült gyorsan megoldani a problémát, kiszállás után megállapítottam hogy a fűtőtest
            zárlatos lett és ez okozta a meghibásodást.</p>
        <p className="mb-5">Volt nálam ilyen alkatrész, csere után minden tökéletesen működött tovább , az ügyfél
            nagy örömére.</p>
    </article>
}, {
    id: 3, image: './assets/work3.jpg', description: <article>
        <p className="mb-5">Whirlpool mosogatógép nem mosogat tisztára és megáll menet közben. A sok ételmaradék
            larakódott a fűtőtestre és a gép többi részére. A motort elakasztotta a sok citrommag. A fűtőszál ennek
            következtében megszakadt, cserére szorult. A gép tisztítását követően újra minden tökéletesen
            működött.</p>
    </article>
}, {
    id: 4, image: './assets/work4.jpg', description: <article>
        <p className="mb-5">Samsung mosógép régi és új fűtőszál.</p>
    </article>
}, {
    id: 5, image: './assets/work5.jpg', description: <article>
        <p className="mb-5">
            LG mosógép dob csillag csere közben a sok lerakódott, túladagolt mosószer maradvány látható.</p>
    </article>
}, {
    id: 6, image: './assets/work6.jpg', description: <article>
        <p className="mb-5">
            Candy szárítógép nem indul el. Hibefelmérés után megállapítottam hogy az ékszíj feszítő görgő megszorult
            és a motorkondenzátor elgyengült. Egy órán belül visszajöttem az új alkatrészekkel és megjavítottam a
            szárítógépet. Az ügyfél nagyon örült hogy nem kellett másik időpontot megbeszélni, még aznap kedvező
            áron megoldottam a problémát.</p>
    </article>
}, {
    id: 7, image: './assets/work7.jpg', description: <article>
        <p className="mb-5">
            25 éves Siemens mosogatógép, nem megy a program, csak szivattyúzik.
            A gép hibafelmérését követően megállapítottam hogy a kora ellenére jó állapotú, érdemes foglalkozni vele,
            annak ellenére hogy sok alkatrész már nem kapható hozzá.
            A programkapcsoló szinkronmotorját kellett cserélni amit már csak bontóban sikerült beszereznem. A csere
            után a gép tovább folytatta jó szolgálatát családja számára.</p>
    </article>
}, {
    id: 8, image: './assets/work8.jpg', description: <article>
        <p className="mb-5">
            Electrolux szárítógép nem szárítja meg a ruhákat. Átvizsgálás után darabokra szedtem a szárítógépet,
            kipucoltam a kondenzátort és a többi elporosodott alkatrészt. Összeszerelés után a hőszivattyú által termelt
            hő mostmár akadálytalanul tud áramlani a szárító egységbe és újra szárazon veszik ki belöle a ruhákat.</p>
    </article>
}]
