import React from 'react';

const Block = ({color, children}) => {
    return (
        <section className={'flex flex-col px-5 ' + color}>
            <div className="container mx-auto md:my-10 px-5 py-5">
                {children}
            </div>
        </section>
    );
};

export default Block;
