import React, {useEffect} from 'react';
import Layout from "../../Components/Layout/Layout";
import Block from "../../Components/Block/Block";
import Footer from "../../Components/Footer/Footer";
import {works} from "../../store/works.js"
import {Helmet} from "react-helmet";

const Works = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const getWorks = () => {
        return works.map(work => <div
            className='border-b border-black mb-5 grid lg:grid-cols-3 lg:w-3/4 lg:mx-auto py-5  gap-5' key={work.id}>
            <div className="">
                <img src={work.image} alt={work.description} className='mx-auto md:object-cover h-80 w-80'/>
            </div>
            <div className={'lg:col-span-2'}>{work.description}</div>
        </div>)
    }
    return (<Layout>
        <Helmet>
            <title>Neptunusz szerviz - Munkáim</title>
            <meta name="description" content='Általam elvégzett munkák, szerelések, megoldások'/>
            <meta name='keywords'
                  content='munkák,alkatresz csere, futoszal, javitas, muszeres meres, szereles'/>
            <meta name='robots' content='follow,index'/>
        </Helmet>
        <div
            className={' w-full  mt-10 lg:mt-32 flex-col gap-4 md:gap-8  z-10'}>
            <div className=" pt-20 md:pt-28 container mx-auto px-5"><a href="/"
                                                                       className='link-primary text-black'>Kezdőlap</a> > <a
                href="/munkáim" className='link-primary'>Munkáim</a></div>
        </div>
        <Block>
            <h1 className={' text-[40px] my-5 uppercase text-left'}> Munkáim </h1>
            {getWorks()}
            <a href="/" className="btn-primary">Vissza&nbsp;a&nbsp;kezdőoldalra</a>
        </Block>
        <Footer/>
    </Layout>);
};


export default Works;
