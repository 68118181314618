export const prices = [
    {
        id: 1,
        categoryName: 'Hiba bevizsgálás',
        categoryItems: [
            {
                id: 1,
                item: 'Mosógép',
                price: '11.500 Ft'
            },
            {
                id: 2,
                item: 'Mosó-szárítógép',
                price: '13.000 Ft'
            },
            {
                id: 3,
                item: 'Mosogatógép (szabadon álló)',
                price: '13.000 Ft'
            },
            {
                id: 4,
                item: 'Mosogatógép (beépített)',
                price: '15.000 Ft'
            },
            {
                id: 5,
                item: 'Szárítógép',
                price: '13.000 Ft'
            }
        ]
    },
    {
        id: 2,
        categoryName: 'Karbantartás',
        categoryItems: [
            {
                id: 1,
                item: 'Szárítógép karbantartás',
                price: '19.000-50.000 Ft'
            },
            {
                id: 2,
                item: 'Klíma tisztítás (kültéri és beltéri)',
                price: '13.000 Ft'
            },
            {
                id: 3,
                item: 'Dugulás elhárítás (mosógép, mosogatógép)',
                price: '18.000-25.000 Ft'
            }
        ]
    },
    {
        id: 3,
        categoryName: 'Üzembe helyezés',
        categoryItems: [
            {
                id: 1,
                item: 'Mosógép üzembe helyezés',
                price: '11.500 Ft'
            },
            {
                id: 2,
                item: 'Mosogatógép (szabadon álló)',
                price: '13.000 Ft',
            },
            {
                id: 3,
                item: 'Mosogatógép (beépített)',
                price: '23.000-28.000 Ft'
            }
        ]
    }
]
