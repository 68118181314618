import React, {useEffect} from 'react';
import Layout from "../../Components/Layout/Layout";
import Footer from "../../Components/Footer/Footer";
import Block from "../../Components/Block/Block";
import clock from '../../assets/101_clock_icon.png';
import w4 from '../../assets/101_icon4.png';
import {Helmet} from "react-helmet";


const Clime = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (<Layout>
        <Helmet>
            <title>Neptunusz szerviz - Szolgáltatásaim</title>
            <meta name="description" content='Mosógépek, mosogatógép, szárítógépek, klímák nélkül ma már el sem tudjuk képzelni az életünket. Mint minden dolog, ami el tud romlani, egyszer sajnos ez is elromlik és ekkor
                                    szüksége
                                    lehet egy profi szerelőre.'/>
            <meta name='keywords'
                  content='klima, javitas, muszeres meres, szereles, elektronikus szamla, keszpenz, bankkartya, garancia'/>
            <meta name='robots' content='follow,index'/>
        </Helmet>
        <div
            className={' w-full  mt-10 lg:mt-32 flex-col gap-4 md:gap-8 z-10'}>
            <div className=" pt-20 md:pt-28 container mx-auto px-5"><a href="/"
                                                                       className='link-primary text-black'>Kezdőlap</a> > <a
                href="/szolgaltatasaim" className='link-primary text-black'>Szolgáltatásaim</a> > <a
                href="/szolgaltatasaim/klima" className='link-primary'>Klíma</a></div>
        </div>
        <Block>
            <div className="flex flex-col gap-y-5 lg:mx-auto lg:w-fit">
                <div className="flex gap-x-5 items-start"><img src={clock} alt=""/>
                    <div className="flex flex-col">
                        <div className="flex flex-col md:flex-row md:gap-x-3 md:items-center text-[16px]"><p>
                            <strong>Munkaidő:</strong>
                        </p>
                            <p className=''>Hétfő-Péntek</p>
                            <p className='text-[20px]'>8:00-17:00</p></div>
                        <div className="flex flex-col md:flex-row md:gap-x-3 md:items-center text-[16px]"><p>
                            <strong>Telefonos ügyelet:
                            </strong>
                        </p>
                            <p className=''>
                                Hétfő-Szombat
                            </p>
                            <p className='text-[20px]'>
                                8:00-19:00
                            </p>
                        </div>
                    </div>

                </div>

                <div className=" flex flex-col w-full lg:grid lg:grid-cols-2 gap-5">
                    <a href="mailto:neptunusz.szerviz@gmail.com"
                       className="btn-primary w-full text-center ">Küldjön&nbsp;üzenetet</a> <a
                    href="tel:+36708010502"
                    className="btn-primary w-full text-center ">hívjon&nbsp;most</a>
                </div>
            </div>
        </Block>
        <Block>
            <div className="flex flex-col mx-auto w-fit">
                <article className={'text-left mx-auto'}>
                    <h3 className={'text-[40px] my-5 uppercase text-center lg:text-left'} id={'klima'}> Klíma
                        Tisztítás </h3>
                    <div className="grid md:grid-cols-4 lg:mx-16 gap-5 my-10">

                        <div className="">
                            <img
                                src={w4} alt="klíma" className='mx-auto'/></div>

                        <div className="md:col-span-3">
                            <p className='my-5'><strong>Klíma tisztítás</strong></p>
                            <ul className='mb-5 list-disc list-outside ml-5  marker:text-brandPrimary'>
                                <li>A nagy nyári melegben és a fűtési időszakban bizony nagy szükségünk lehet egy klíma
                                    berendezésre, mely élvezhetőbbé teszi a benti hőmérsékletet ezzel növelve a
                                    komfortérzetünket.
                                </li>
                                <li>Ezek a készülékek a használat és a nyugalmi időszak során baktériumok, gombák
                                    telephelyévé válhatnak és elporosodhatnak, ezért szükséges a rendszeres, legalább
                                    évenként egyszer a szakszerű karbantartást elvégezni.

                                </li>
                                <li>A Neptunusz Szeriz ebben segít önnek, hétfőtől szombatig 8-19 óra között fogadom
                                    hívását és állok az Ön rendelkezésére.
                                </li>
                            </ul>

                            <p className='flex md:hidden items-center px-5 md:px-0'>
                                <a
                                    href="tel:+36708010502"
                                    className="btn-primary w-full text-center">hívjon&nbsp;most</a></p>
                            <p className={'hidden md:block'}>
                                Keressen bizalommal, a következő telefonszámon: +36708010502
                            </p>
                        </div>
                    </div>
                    <div
                        className="border-2 border-brandDark px-16 md:px-72 py-5 text-center font-normal w-fit mx-auto">
                        <strong>6
                            hónap
                            garancia
                        </strong> minden beépített
                        alkatrészre
                    </div>
                </article>
            </div>

        </Block>
        <Block>
        </Block>
        <Footer isMapVisible={true}/>
    </Layout>);
};

export default Clime;
